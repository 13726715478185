// CategoryListPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import './category.scss';
import Navbar from '../../component/navbar/Navbar'
import Footer from '../../component/fotter/Footer'


const CategoryListPage = () => {
  // Accessing route parameters using useParams hook
  const { category } = useParams();

  // Sample data for video lists (replace with actual data)
  const videoLists = {
    music: [
      { id: 1, title: 'Music Video 2', videoId: 'b2Kx3Xfek6c', description: 'Description for Music Video 2' },
      { id: 2, title: 'Music Video 1', videoId: 'CxUw5tg0QSQ', description: 'Description for Music Video 1' },
      { id: 2, title: 'Music Video 3', videoId: 'xKrw9LIkAeU', description: 'Description for Music Video 3' },
      { id: 2, title: 'Music Video 4', videoId: 'SUdDn_sUTgo', description: 'Description for Music Video 4' },
      { id: 2, title: 'Music Video 5', videoId: 'FiOc3ZfFRIg', description: 'Description for Music Video 5' },
      { id: 2, title: 'Music Video 6', videoId: '2il-iEGnrgU', description: 'Description for Music Video 6' },
      { id: 2, title: 'Music Video 7', videoId: 'GE_lw7kWc9E', description: 'Description for Music Video 7' },
      { id: 2, title: 'Music Video 8', videoId: '613pL3LbfAY', description: 'Description for Music Video 8' },
      // Add more videos for the Music category as needed
    ],
    history: [
      { id: 1, title: 'History Video 1', videoId: '_wJ6BHiWwaw', description: 'Description for Tech Video 1' },
      { id: 2, title: 'History Video 2', videoId: '4L3uGjlQPKA', description: 'Description for Tech Video 2' },
      { id: 2, title: 'History Video 2', videoId: 'c8oyqCdEQ8k', description: 'Description for Tech Video 2' },
      { id: 2, title: 'History Video 2', videoId: 'dvQ7GeRiyQg', description: 'Description for Tech Video 2' },
      { id: 2, title: 'History Video 2', videoId: 'XWJElFtFSR4', description: 'Description for Tech Video 2' },
      { id: 2, title: 'History Video 2', videoId: 'BOc6uD5CW98', description: 'Description for Tech Video 2' },
      { id: 2, title: 'History Video 2', videoId: 'Zm_VbN-LLS8', description: 'Description for Tech Video 2' },
      { id: 2, title: 'History Video 2', videoId: 'wJelEXaPhJ0', description: 'Description for Tech Video 2' },
      // Add more videos for the Technology category as needed
    ],
    adwa: [
      { id: 1, title: 'historical Video 1', videoId: '3Uonn7EL9lY', description: 'Description for Cooking Video 1' },
      { id: 2, title: 'historical Video 2', videoId: '78UOZi6Tz2Y', description: 'Description for Cooking Video 2' },
      { id: 2, title: 'historical Video 2', videoId: 'y3_9sv6h6M0', description: 'Description for Cooking Video 2' },
      { id: 2, title: 'historical Video 2', videoId: 'e4jQmASWOAM', description: 'Description for Cooking Video 2' },
      { id: 2, title: 'historical Video 2', videoId: 'vUz_Lp1dPWw', description: 'Description for Cooking Video 2' },
      { id: 2, title: 'historical Video 2', videoId: 'NvrwYOZ4K2Y', description: 'Description for Cooking Video 2' },
    ],
    travel: [
      { id: 1, title: 'Travel Video 1', videoId: '2xW9mTStyhM', description: 'Description for Travel Video 1' },
      { id: 2, title: 'Travel Video 2', videoId: 'HwgnhdZBI-M', description: 'Description for Travel Video 2' },
      { id: 2, title: 'Travel Video 2', videoId: 'wJelEXaPhJ0', description: 'Description for Travel Video 2' },
      { id: 2, title: 'Travel Video 2', videoId: 'wJelEXaPhJ0', description: 'Description for Travel Video 2' },
      { id: 2, title: 'Travel Video 2', videoId: 'wJelEXaPhJ0', description: 'Description for Travel Video 2' },
      { id: 2, title: 'Travel Video 2', videoId: 'wJelEXaPhJ0', description: 'Description for Travel Video 2' },
      { id: 2, title: 'Travel Video 2', videoId: 'wJelEXaPhJ0', description: 'Description for Travel Video 2' },
      { id: 2, title: 'Travel Video 2', videoId: 'wJelEXaPhJ0', description: 'Description for Travel Video 2' },
      // Add more videos for the Travel category as needed
    ],
  };

  const videos = videoLists[category] || [];

  return (
    <div className="category-list-page">
           <Navbar/>
      <h1>{category.toUpperCase()} Videos</h1>
      <div className="video-container">
        {videos.map(video => (
          <div key={video.id} className="video-item">
            <div className="video-thumbnail">
              {/* Embedded YouTube video */}
              <iframe
                title={video.title}
                width="100%"
                height="200"
                src={`https://www.youtube.com/embed/${video.videoId}`}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-details">
              <h3>{video.title}</h3>
              <p>{video.description}</p>
            </div>
          </div>
        ))}
      </div>
      <Footer/>
    </div>
  );
};

export default CategoryListPage;