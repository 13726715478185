// Category.js
import React from 'react';
import { Link } from 'react-router-dom';
import './category.scss';
import Navbar from '../../component/navbar/Navbar'
import Footer from '../../component/fotter/Footer'

const Category = () => {
  // Sample data for video categories with descriptions, videos, tags, and ratings
  const videoCategories = [
    { id: 1, name: 'Music', description: 'Explore the Cultur of music videos.', video: 'CxUw5tg0QSQ', tags: ['Cultural'], rating: 4.5 },
    { id: 2, name: 'History', description: 'Discover the Historical event in Ethiopia.', video: 'Zm_VbN-LLS8', tags: ['Gadgets', 'AI'], rating: 4.0 },
    { id: 3, name: 'Adwa', description: 'Adwa was a crushing defeat and effectively ended the war. Within the year, Italy would sign a treaty formally recognizing Ethiopian independence and the other states of Europe followed. Others saw that victory as well. .', video: '3Uonn7EL9lY', tags: ['ADWA', 'Ethiopian war'], rating: 4.2 },
    { id: 4, name: 'Travel', description: 'Experience .', video: '2xW9mTStyhM', tags: ['Adventure', 'Culture'], rating: 4.8 },
    // Add more categories with descriptions, videos, tags, and ratings as needed
  ];

  return (
    <div className="category-page">
           <Navbar/>
      <h1 className="page-title">Video Categories</h1>
      <div className="category-list">
        {videoCategories.map(category => (
          <div key={category.id} className="category-item">
            <Link to={`/category/${category.name.toLowerCase()}`} className="category-link">
              <div className="category-image">
                {/* Embedded YouTube video */}
                <iframe
                  title={category.name}
                  width="100%"
                  height="200"
                  src={`https://www.youtube.com/embed/${category.video}`}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="category-content">
                <h3>{category.name}</h3>
                <p>{category.description}</p>
                <div className="category-tags">
                  {category.tags.map((tag, index) => (
                    <span key={index} className="tag">{tag}</span>
                  ))}
                </div>

                <button className="btn-watch">Watch Now</button>
                {/* Additional category item content can be added here */}
              </div>
            </Link>
          </div>
        ))}
      </div>
      {/* Video Player section */}
      <div className="video-player">
        {/* Placeholder for video player */}
      </div>
      <Footer/>
    </div>
  );
};

export default Category;