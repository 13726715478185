import React from 'react'
import './conatct2.scss'
import Navbar from '../../component/navbar/Navbar'
import Footer from '../../component/fotter/Footer'
import Lottie from "lottie-react";
import ani from "../../image/DZRyTAjqAw.json";



const Contact = () => {
  return (
    <div className="cont">
        <Navbar/>
        <div className="section1">
      <Lottie className='lottie1' animationData={ani} />
     
      <div className="p">
      <h3>
        Contact <span>us</span>
      </h3>
        
        </div>
     
    </div>    
    <div className="middle">
    <form action="connect1.php" className="form" method="post">
      <label htmlFor="Fname">First name</label><br />
      <input type="text" id="fname" name="firstname" /><br /><br />
      <label htmlFor="Lname">Last name</label><br />
      <input type="text" id="lname" name="lastname" /><br /><br />
      <label htmlFor="Email">Email</label><br />
      <input type="email" id="email" name="email" /><br /><br />
      <label htmlFor="Comment">Comment</label><br />
      <textarea id="" cols="50" rows="5" name="messages"></textarea><br />
      <button className="btn1" >Submit</button>
    </form>
  
    <div className="con-p">
    <Lottie className='lottie' animationData={ani} />
    </div>
  </div>
        <Footer/>
    </div>
  )
}

export default Contact