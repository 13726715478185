import React, { useState, useEffect } from 'react';
import firebase from '../../firebaseConfige';
import Navbar from '../../component/navbar/Navbar'
import './blogs.scss'

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetchBlogsFromFirebase();
  }, []);

  const fetchBlogsFromFirebase = async () => {
    try {
      const blogsRef = firebase.firestore().collection('blogs');
      const snapshot = await blogsRef.get();
      const fetchedBlogs = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          title: data.title,
          content: data.content,
          userId: data.userId
        };
      });
      setBlogs(fetchedBlogs);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  return (
    <div className='bloge'>
      
      <Navbar/>
       
      <h1>Blogs</h1>
      <ul>
        {blogs.map(blog => (
          <li key={blog.id}>
            <h2>{blog.title}</h2>
            <p>{blog.content}</p>
            <p>User ID: {blog.userId}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Blogs;
