import React, { useState, useEffect } from 'react';
import Navbar from '../../component/navbar/Navbar'
import Footer from '../../component/fotter/Footer'
import "./home.scss"
import { useNavigate } from 'react-router-dom'
import ParticlesBackground from '../../component/config/ParticlesBackground'
import ReactPlayer from 'react-player'
import logo from "../../image/logo-removebg-preview.png";
import logo2 from "../../image/logo-removebg-preview.png";
import mypic1 from "../../image/Ethiopia-News-Genene-Mekuria.jpg"
import mypic2 from "../../image/maxresdefault.jpg"
import firebase from '../../firebaseConfige'; 

const Home = () => {
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    fetchVideosFromFirebase();
  }, []);
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    fetchBlogsFromFirebase();
  }, []);
  const fetchBlogsFromFirebase = async () => {
    try {
      const blogsRef = firebase.firestore().collection('blogs');
      const snapshot = await blogsRef.get();
      const fetchedBlogs = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          title: data.title,
          content: data.content,
          userId: data.userId // Assuming the user ID is stored directly in the blog document
        };
      });
      setBlogs(fetchedBlogs);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  const renderBlog = (blog) => {
    return (
      <div key={blog.id} className="blogContainer">
        <h3>{blog.title}</h3>
        <p>{blog.content}</p>
        {/* You can display additional information about the blog or the user here if needed */}
      </div>
    );
  };
 const fetchVideosFromFirebase = async () => {
    try {
      const userVideosRef = firebase.firestore().collectionGroup('user-videos');
      const snapshot = await userVideosRef.get();
      const fetchedVideos = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          title: data.title,
          description: data.description,
          videoURL: data.videoURL,
          uploader: data.uploader,
          userId: doc.ref.parent.parent.id // Assuming the user ID is stored in the parent document
        };
      });
      setVideos(fetchedVideos);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  const renderVideo = (video) => {
    return (
      <div key={video.id} className="videoContainer1">
        <video controls width={270} height={200}>
          <source src={video.videoURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="videoInfo">
          <h3>{video.title}</h3>
          <p>{video.description}</p>
          <p>Uploader: {video.uploader}</p>
          {/* You can display additional user details here if needed */}
        </div>
      </div>
    );
  };
  
  // const VideoList = ({ videos }) => {
  //   return (
  //     <div className="lateVidContent">
  //       {videos.map(renderVideo)}
  //     </div>
  //   );
  // };
  const navigate  = useNavigate();

  const handleReadMore = () => {
    navigate("/Category");
  };
  return (
    
      <div className="home">
         <Navbar/>
         <div className="section2">
         <ParticlesBackground/>
         <img className='logo'
            src={logo}
          />
      <div className="p">
      <h3>
        Welcome to <span>Ethiopian Hero Stories</span>
      </h3>
        <p>
        Established in [2024],<br/> 
        Mindpluse Solution is a pioneering 
        force in the realm of advanced digitalization technology. 
        With a visionary approach, the company has been at the forefront of revolutionizing 
        traditional business practices through cutting-edge digital solutions.
     
        </p> 
        </div>
     
      </div>
         
    <div className="mid-section">
        
        <img className='img2' src={logo2} alt="" />
     <div className="p">
     <h3>
       About us
     </h3>
       <p>
       3.  Vision: Mindpluse Solution, Envisions a future where businesses seamlessly integrate digital technologies into their operations, maximizing efficiency, productivity, and sustainability.<br/>
       <br/>
       4.  Core Values:<br/>
         a.  Innovation: Constantly striving to push the boundaries of what's possible in digitalization technology.<br/>
         <br/>
         b.  Excellence: Commitment to delivering exceptional solutions and services that exceed customer expectations.<br/>
         <br/>
         c.  Collaboration: Fostering a culture of teamwork and cooperation among employees, clients, and partners.<br/>
         <br/>
         d.  Integrity: Upholding the highest standards of honesty, ethics, and transparency in all endeavors.<br/>
      
       </p> 
     {/* //  <Link to="/Contact">
     
      // </Link> */}
      <a><button >Read more</button></a>
       </div>
      
    </div>
    <div className="lateVidContent">
        {videos.map(renderVideo)}
    </div>
    <br/>
    <div className="">
        {blogs.map(renderBlog)}
    </div>
    <div className="catagory">
      <h2>Ethiopia History</h2>
            <div className="cog">
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=CxUw5tg0QSQ&ab_channel=YOOYYAATUBE"} height={"200px"} width={"270"} loop controls/></div>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=_wJ6BHiWwaw"} height={"200px"} width={"270"}loop controls/></div>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=A4OSEpexs_Q"} height={"200px"} width={"270"}loop controls/></div>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=UpndwGQ1iFE"} height={"200px"} width={"270"}loop controls/></div>
          <button onClick={handleReadMore}>Read more</button>
            </div>
           
            <div className="phy">
            <h2>The Battle Of ADWA</h2>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=3Uonn7EL9lY"} height={"200px"} width={"270"} loop controls/></div>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=e4jQmASWOAM"} height={"200px"} width={"270"}loop controls/></div>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=78UOZi6Tz2Y"} height={"200px"} width={"270"}loop controls/></div>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=-rxBKLpJCOE"} height={"200px"} width={"270"}loop controls/></div>
          <button onClick={handleReadMore}>Read more</button>
            </div>
            <div className="sir">
            <h2>Ethiopian Heros</h2>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=4FnXzXut-Ss"} height={"200px"} width={"270"} loop controls/></div>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=OrHp3EW5XOs"} height={"200px"} width={"270"}loop controls/></div>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=DHaG_5ygWys"} height={"200px"} width={"270"}loop controls/></div>
          <div className="video-placeholder"><ReactPlayer url={"https://www.youtube.com/watch?v=xjz1T_fzoUA"} height={"200px"} width={"270"}loop controls/></div>
          <button onClick={handleReadMore}>Read more</button>
            </div>
      </div>

     {/* Team member section */}
    
    <br/>
    <div className="sec_mid">
    <div className="title">

    </div>
  
 
  <div className="team-member-section">
        <h2>Our Narriators</h2>
        <div className="team-member-container">
          {/* Team member card */}
          <div className="team-member-card">
            <div className="image-container">
              <img src={mypic1}></img>
            </div>
            <div className="info-container">
              <h2 className="name">Genene Mekuria</h2>
              <p className="position">Journalist</p>
            </div>
          </div>
          {/* Add more team member cards */}
          <div className="team-member-card">
            <div className="image-container">
              <img src={mypic2}/>
            </div>
            <div className="info-container"><br/>
              <h2 className="name">Zenebe Wola</h2>
              <p className="position">Auther</p>
            </div>
          </div>
          {/* Add more team member cards as needed */}  
          <div className="team-member-card">
            <div className="image-container">
              <img src={mypic2}/>
            </div>
            <div className="info-container">
              <h2 className="name">Zenebe Wola</h2>
              <p className="position">Auther</p>
            </div>
          </div>
          {/* Add more team member cards as needed */}
          <div className="team-member-card">
            <div className="image-container">
              <img src={mypic1} />
            </div>
            <div className="info-container">
              <h2 className="name">Genene Mekuria</h2>
              <p className="position">Journalist</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bon1">
  <a href="cause.html"><button >View more</button></a>
  </div>
  
   </div>
         <Footer/>
      </div>
  )
}

export default Home