import React from 'react'
import Particles from "react-particles"
import particlesConfig from '../config/particles-confige'
const ParticlesBackground = () => {
  return (
    <Particles params={particlesConfig}>
        
         </Particles>
  )
}

export default ParticlesBackground