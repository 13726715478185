import React from 'react'
import './about.scss'
import Navbar from '../../component/navbar/Navbar'
import Footer from '../../component/fotter/Footer'
import Lottie from "lottie-react";
// import ParticlesComponent from '../../component/config/particles';
import ani from "../../image/7jav450P4r.json"
import doc from "../../image/IqSDnachVv.json"
import doc3 from "../../image/qMfsEBDmdZ.json"
import doc1 from "../../image/7DyYojvanJ.json"
import doc2 from "../../image/Wjtau4tyWt.json"



const About = () => {
  return (
    
    <div className="about">
      
        <Navbar/>
       
      <div className="section1">
      <Lottie className='lottie' animationData={ani} />
     
      <div className="p">
      <h3>
        About <span>us</span>
      </h3>
        </div>
     
    </div>
  
    <div className="detail">
        <div className="de1">
        <Lottie className='lottie' animationData={doc3} />
        <p>
        3.  Vision: Haqaa Hub Mindpluse Solution, Envisions a future where businesses seamlessly integrate digital technologies into their operations, maximizing efficiency, productivity, and sustainability.
        </p>
        </div>
        <div className="de2">
        
        <p>
        4.  Core Values:<br/>
        <br/>
        a.  Innovation: Constantly striving to push the boundaries of what's possible in digitalization technology.<br/>
        <br/>
        b.  Excellence: Commitment to delivering exceptional solutions and services that exceed customer expectations.<br/>
        <br/>
        c.  Collaboration: Fostering a culture of teamwork and cooperation among employees, clients, and partners.<br/>
        <br/>
        d.  Integrity: Upholding the highest standards of honesty, ethics, and transparency in all endeavors<br/>
        </p>

        <Lottie className='lottie' animationData={doc1} />
        </div>
        <div className="de3">
        <Lottie className='lottie' animationData={doc2} />
        <p>
        Vision: Haqaa Hub Mindpluse Solution, Envisions a future where businesses seamlessly integrate digital technologies into their operations, maximizing efficiency, productivity, and sustainability
        </p>

        
        </div>
   </div>
        <Footer/>
    </div>
  )
}

export default About