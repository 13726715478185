import React from 'react'
import Navbar from '../../component/navbar/Navbar'
import Footer from '../../pages/doctor/fotter/Footer1'
import List from '../../pages/doctor/list/List'
import './doctor.scss'
export default function Doctor() {
  return (
    <div className="doc">
        <Navbar/>
        <div className="title">
        <h2>our specalized doctors</h2>
        </div>
         <div className="mel1">
          <h3>Mental Health</h3>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam perspiciatis necessitatibus, nulla repellat error eveniet cupiditate? Commodi iure minima, cumque quibusdam illum quae veniam quaerat maiores quasi corporis quos perspiciatis!
          </p>
         <List/>
        </div>
       <div className="mel2">
          <h3>Physical Health</h3>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam perspiciatis necessitatibus, nulla repellat error eveniet cupiditate? Commodi iure minima, cumque quibusdam illum quae veniam quaerat maiores quasi corporis quos perspiciatis!
          </p>
         <List/>
        </div>
        <div className="mel3">
          <h3>Spirtual Health</h3>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam perspiciatis necessitatibus, nulla repellat error eveniet cupiditate? Commodi iure minima, cumque quibusdam illum quae veniam quaerat maiores quasi corporis quos perspiciatis!
          </p>
         <List/>
        </div>
        <Footer/>
    </div>
  )
}
