import "./listItem.scss";
import { useState } from "react";

export default function ListItem({ index }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`listItem ${isHovered ? 'hovered' : ''}`}
      style={{ left: isHovered && index * 225 - 50 + index * 2.5 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src="../../image/gtAiQ2YSg8a3HcrMfPeN21JC9GRgCopJ.jpg" alt="" />
      {isHovered && (
        <button className="visitButton">Visit</button>
      )}
    </div>
  );
}
