// App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import About from './pages/about/About';
import Doctor from './pages/doctor/Doctor';
import Login from './pages/login/UserLogin';
import Category from './pages/category/Category';
import CategoryListPage from './pages/category/CategoryListPage';
import BlogUploader from './pages/blogs/Blogs';
import '/'


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/doctor" element={<Doctor />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/BlogUploader" element={<BlogUploader />} />
      

        {/* Dynamic route for category pages */}
        <Route path="/category/:category" element={<CategoryListPage />} />
      </Routes>
    </Router>
  );
};

export default App;
