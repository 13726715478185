import React, { Component } from 'react'
import './footer.scss'
import mylogo from "../../image/logo-removebg-preview.png"
export class Footer extends Component {
  render() {
    return (
        <div class="footer">
        <div class="container">
            <div class="row">
                <div class="footer-col">
                    <img src={mylogo}/>
                </div>
                <div class="footer-col">
                    <h4>Quick links</h4>
                    <ul>
                        <li><a href="effect.html">Contact us</a></li>
                        <li><a href="cause.html">About us</a></li>
                        <li><a href="solution.html">Help</a></li>
                        
                    </ul>
                </div>
                <div class="footer-col">
                    <h4>call us.</h4>
                    <ul>
                        <li><a href="">+251234567</a></li>
                        <li><a href="">+251246822</a></li>
                        <li><a href="">+251359765</a></li>
                        <li><a href=""></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    )
  }
}

export default Footer