import "./login.scss";
import { Link, useNavigate } from 'react-router-dom';

export default function Login() {
  const navigate  = useNavigate();

  const handleReadMore = () => {
    navigate("/Home");
  };

  const handleContentCreator = () => {
    window.open("http://uploader.ethiopianheros.mindpulsetech.com/", "_blank");
  };

  return (
    <div className="login">
      <div className="top">
        <div className="wrapper">
          <img
            className="logo"
            src="../image\photo_2024-03-11_15-08-11-removebg-preview.png"
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <form>
          <h4>Sign In</h4>
          <input type="phone" placeholder="phone number" />
          <input type="OTP" placeholder="OTP" />
          <b>Forget Password ?</b><br/>
          <button onClick={handleReadMore} className="loginButton">Sign In</button>
          <b>Are you content creator ?</b><br/>
          <button onClick={handleContentCreator}>Content Creator</button>
        </form>
      </div>
    </div>
  );
}
